.front-page-container {
    position: relative;
    padding: 100px 50px 50px;
    margin-bottom: 25px;

    @media (max-width: 768px) {
        padding: 80px 15px 10px 15px;
  }
}

.front-page-title {
    color: white;
}

.front-page-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.carousel-container {
    padding: 0.5rem 0 2rem;
}