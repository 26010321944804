.gallery-content {
  position: relative;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 100px;

  @media only screen and (max-width: 680px) {
    padding-top: 80px;
  }
}

.gallery-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7.5px;
  padding: 10px 40px 20px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding: 10px 10px 20px;
  }
}

.gallery-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 7.5px;
  justify-content: flex-start;
}

.gallery-size {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 2.5px;
  justify-content: flex-start;
}

.button {
  color: white;
  background: transparent;
  border-radius: 4px;
}

.button:hover {
  background-color: cadetblue;
}

.selected {
  background-color: cadetblue;
}

.all-btn {
  margin-right: 0.5rem
}

.all-chapters-button {
  margin-right: .45rem;
}

/* CSS for the image list */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.one {
  width: 300px;
  height: 360px;
}

.two {
  width: 200px;
  height: 240px;
}

.three {
  border-radius: 5px !important;
}

.one-image {
  width: 300px;
  height: 400px;
}

.two-image {
  width: 200px;
  height: 270px;
}

.three-image {
  width: 400px;
}

.one-image-text {
  font-size: large !important;
}

.two-image-text {
  font-size: small !important;
}

.three-image-text {
  font-size: xx-small !important;
}

.hide-three-image-text {
  display: none;
}

.size-icon-one {
  width: 22.5px;
  height: 22.5px;
  padding: 1px;
  display: flex;
}

.size-icon-two {
  width: 25px;
  height: 25px;
  display: flex;
}

.size-icon-three {
  width:  17.5px;
  height: 17.5px;
  padding: 4px;
  display: flex;
}

.image-container {
    text-decoration: none;
    position: relative; /* Establish a positioning context */
    text-align: center; /* Center content */
    box-sizing: border-box;
}

/* CSS for the image container */
.image-content {
    display: block;
}
  
/* CSS for the image */
.image-content img {
  border-radius: 5px 5px 0px 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-text-box {
  background-color: black;
  opacity: 75%;
  padding: 0.5rem;
  border-radius: 0px 0px 5px 5px;
}

.image-text {
  color: white;
  font-size: large;
  margin: 0;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.shine {
  position: relative;
  overflow: hidden;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 12px;
  font-family: sans-serif;
}

.shine:after {
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 1;
  transform: rotate(30deg);
  
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

/*---
  hover trigger
---*/

.shine:hover:after {
  opacity: 1;
  top: -35%;
  left: -35%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}


/*---
  active state
---*/

.shine:active:after {
  opacity: 0;
}