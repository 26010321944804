.offersContainer {
  margin: 0;
}

.offersContent {
  color: white;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  padding: 0.25rem 1.5rem 1.5rem;
  flex-direction: column;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.offer-list {
  width: 100%;
  border-collapse: collapse;
}

.offer-list thead th {
  font-weight: 400;
  color: lightgray;
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.offer-list tbody tr {
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.offer-list td {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.75rem;
}

.offer-list td:nth-child(3) {
  border-bottom: none;
  max-width: 150px;
}

.offers-address {
  text-decoration: none;
  color: white;
  max-width: 150px;
}

.offers-address:hover {
  text-decoration: none;
  color: lightblue;
}

.offers-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.offers-list-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media (max-width: 600px) {
  .offer-list td:nth-child(3) {
    border-bottom: none;
    max-width: none;
  }

  .offer-list thead {
    display: none;
  }

  .offer-list tbody tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }

  .offer-list td {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .offer-list td:before {
    content: attr(data-label);
    font-weight: bold;
    color: lightgray;
    width: 40%;
  }
}