.card {
    display: block;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}
  
.card:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 16px;
    background: linear-gradient(to top, oklch(0.1 0.2 240 / 0.95),
    oklch(0.1 0.2 240 / 0.95)) padding-box, conic-gradient(from var(--bg-angle) in oklch longer hue,
    oklch(0.85 0.37 0) 0 0) border-box;
    opacity: 0.9;
    color: #E5E5E5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.card-title {
    margin: 0 0 8px;
}

.card-description {
    margin: 0;
}
