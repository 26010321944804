.header{
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0px;
}

.headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 2.5rem;
    background: linear-gradient(to top, oklch(0.1 0.2 240 / 0.95),
    oklch(0.1 0.2 240 / 0.95)) padding-box, conic-gradient(from var(--bg-angle) in oklch longer hue,
    oklch(0.85 0.37 0) 0 0) border-box;
    opacity: 0.9;
    color: #E5E5E5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);

    @media only screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        flex-wrap: nowrap;
    }
}

.header-links-container {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.wallet-connect-container {
    display: flex;
    flex-direction: row;
}

.link {
    padding-right: 2rem;
    text-decoration: none;
    color: white;

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.header-link-text {
    font-size: large;
    color: #fff;
    margin: 0;
}

.colorfuel-title {
    margin: 0.75rem;
}

.colorfuel-icon {
    width: 35px;
    height: 40px;
    background-color: white;
}

.colorfuel-link {
    padding-right: 2rem;
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 768px) {
        padding-right: 0rem;
    }
}