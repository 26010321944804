.overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    color: white;
    padding: 65px 25px 20px;
    border-radius: 8px;
    position: relative;
    width: 80%;
    max-width: 500px;
    max-height: 500px;
    overflow-x: auto;
}

.closeButton {
    position: absolute;
    top: 25px;
    right: 25px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
}

.offerTitle {
    position: absolute;
    top: 25px;
    left: 25px;
    font-weight: 600;
    font-size: x-large;
}

.modalNftInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.modalNftInfoBox {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    flex-wrap: wrap;
}

.modalNftOfferPriceBox {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
}

.modalNftPicture {
    border-radius: 5px;
    width: 25%;
    max-height: 150px;

    @media (max-width: 768px) {
        width: auto;
  }
}

.modalNftDescription {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
}

.modalNftName {
    font-size: large;
    font-weight: 700;
    padding-bottom: 0.25rem;

    @media (max-width: 768px) {
        font-size: small;
  }
}

.modalNftCollection {
    font-size: medium;
    font-weight: 300;

    @media (max-width: 768px) {
        font-size: small;
  }
}

.modalNftStatsContent {
    padding: 1rem;
    border: 1px;
    border-radius: 4px;
    background: #0d0973;
}

.walletBalanceContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wethWalletBalance {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.priceContent {
    width:100%;
    display: flex;
    margin: 1rem 0 0.5rem;
}

.priceValuta {
    justify-content: center;
    border: 1px solid white;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    padding: 0.65rem 1rem;
}

.priceInput {
    height: 40px;
    width: inherit;
    border-radius: 4px 0 0 4px;
    border: 1px solid;
    background: transparent;
    color: white;
    padding-left: 15px;
}

input:focus {
    border: 0;
    outline: 1.5px white solid;
}

.makeOfferModalButtonContainer {
    padding: 1rem 0 1rem;
    width:100%;
    height: 50px;
}

.makeOfferModalButton {
    margin: 0 !important;
    width:inherit;
    height: inherit !important;
    font-size: large;
    font-weight: 500;
    border-radius: 4px;
}

.duration {
    margin-top: 1rem;
    font-size: larger;
    font-weight: 400;
}

.datePickerContainer {
    display: flex;
    text-align: center;
    position: relative;
    gap: 0.5rem;

    @media (max-width: 670px) {
        flex-wrap: wrap;
  }
}

.datepicker-content {
    display: flex;
    width: 100%;
}

.datepicker-input {
    padding: 1.5px 10px;
    margin: 0.75rem 0 0.25rem;
    font-size: large;
    height: 35px;
    width: 100%;
    color: black;
}

.errorMessage {
    font-size: small;
    font-weight: 400;
    color: rgb(255, 0, 0);
}