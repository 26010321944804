.timePickerContainer {
    position: relative;
    display: inline-block;
}

.timeIntervalButton {
    padding: 0.25rem 0.5rem !important;
    margin: 0.75rem 0 0.25rem !important;
    font-size: medium;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 3.5rem;

}

.timeIntervalListItem {
    padding: 8px;
    cursor: pointer;
}

.timeIntervalListItem:hover {
    background-color: gray;
}

.timerIntervalItem {
    padding: 0.2rem;
}

.timeIntervalList {
    position: absolute;
    bottom: 100%; /* This will move the dropdown upwards above the button */
    left: 0;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%; /* Adjust the width as necessary */
    text-align: left;
}

.dropDownIcon {
    max-width: 30px;
    max-height: 30px;
}