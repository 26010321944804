
.pictureWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 150px;
    top: 92.5px;

    @media only screen and (max-width: 670px) {
        top: 80px;
    }
}

.mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
    width: 90vw;
    gap: 1rem;
}

.pictureInfoContent {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 4.5rem;

    @media (max-width: 768px) {
        gap: 1rem;
        align-items: center;
    }
}

.pictureInfoContent > div:first-child {
    position: absolute;
    top: 15px;
    width: calc(82vw - 0.25rem);
    box-sizing: border-box; 
}

.nftDescription {
    margin-bottom: 0.5rem;
}

.offerListContent {
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.offerListContainer {
    width: 100%;
    margin: 0 8rem;
}

.offersAndInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pictureContainer {
    border-radius: 5px;
    text-align: left;
    color: white;
    padding: 1.5rem;
    padding-top: 0.25rem;
    display: flex;
    flex-direction: row;
    max-height: 520px;
    max-width: fit-content;
    
    @media (max-width: 670px) {
        min-width: 80vw;
        flex-direction: column;
    }
}

.pictureContainer-img {
    background: none;
} 

.picture {
    border-radius: 5px;
    width: 500px;
    height: auto;
    object-fit: cover;
    transition: transform 5.3s;

    @media only screen and (max-width: 670px) {
        width: 95%;
    }
}

.pictureButtonContainer {
    padding-left: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 250px;

    @media only screen and (max-width: 670px) {
        min-width: 200px;
        padding-left: 0px;
    }
}

.pictureButtonContent {
    display: flex;
    align-items: center;
    gap: 15px;
}

.pictureButtonContainer button {
    margin: 0.2rem;
    padding: 0;
    height: fit-content;
    max-height: 65px;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pictureButtonContainer p {
    font-size: 1rem;
    font-weight: 400;
}

.pictureButtonPrint {
    border-radius: 3px;
    height: 60px !important;
    border: 1px solid black;
    background-color: orange;
}

.pictureButtonPrint p {
    font-size: 1rem;
    font-weight: 600;
}

.nftOwnerLink {
    color: gold; /* Make the text white */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Ensure proper layout */
    overflow: hidden; /* Hide overflowing text */
    width: 250px; /* Set the maximum width */
    white-space: nowrap; /* Prevent line breaks */
    position: relative;
}

.marquee {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%; /* Matches the width of the container */
}
  
.marquee span {
    display: inline-block;
    padding-left: 100%; /* Start the text off-screen */
    animation: scroll 5s linear infinite; /* Create the scrolling effect */
}

@keyframes scroll {
    from {
      transform: translateX(0); /* Start at the beginning */
    }
    to {
      transform: translateX(-100%); /* End off-screen to the left */
    }
}
  
.nftOwnerLink:hover .marquee span {
    color: lightgoldenrodyellow;
    animation-play-state: paused; /* Pause animation on hover */
}

.makeOfferButton {   
    background-image: linear-gradient(to right, #4CAF50, #008CBA);
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    min-height:60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.externalLinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2.5px;
    gap: 2.5px;
}

.externalLinkWrapper {
    text-decoration: none;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.externalLink {
    text-decoration: none;
    min-width: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
}

.offersLinkWrapper {
    text-decoration: none;
    width: 120px;
    display: flex;
    justify-content: center;
}

.offersLink {
    text-decoration: none;
    height: 65px;
    min-width: 65px;
    display: flex;
    justify-content: center;
    color: white;
}

.offersLinkText {
    font-weight: 700 !important;
}

.makeOffer {
    text-decoration: none;
    color: black;
    height: 50px;
    display: flex;
    justify-content: center;
}

.small-header {
    padding-left: 1rem;
}

.etherscan-header {
    ::before {
        padding-left: 1rem;
    }
}

/*---
  animated rainbow background
---*/

@property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: "<angle>";
  }
  
  /**
   * To animate the gradient, we set the custom property to 1 full
   * rotation. The animation starts at the default value of `0deg`.
   */
  @keyframes spin {
    to {
      --bg-angle: 360deg;
    }
  }
  
  
  .gradient {
    /* add the animation, but pause it by default */
    animation: spin 2.5s infinite linear paused;
    animation-play-state: running;
    
    /**
     * Using `background-origin` we can create a “border” using two gradients. And to
     * make the gradients better-looking, we use OKLCH.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-origin
     * @see https://evilmartians.com/chronicles/oklch-in-css-why-quit-rgb-hsl
     */
    background:
      /* Background colors don’t work with `background-origin`, so use a gradient. */
      linear-gradient(
          to top,
          oklch(0.1 0.2 240 / 0.95),
          oklch(0.1 0.2 240 / 0.95)
        )
        padding-box, /* ends at inner border edges */
      conic-gradient(
          from var(--bg-angle) in oklch longer hue,
          oklch(0.85 0.37 0) 0 0
        )
        border-box; /* extends to outer border edges */
    
    /* a clear border lets the background gradient shine through */
    border: 0.75px solid transparent;
  }

  .popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    display: flex;
    align-items: center; /* Align icon and text vertically centered */
    background-color: #1a2a6c; /* Default deep blue background */
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    opacity: 0;
    transform: scale(0.8);
    z-index: 1000;
    animation: popIn 0.3s ease-out forwards, fadeOut 10s ease-out forwards 0.3s;
}

/* Specific colors for success and error types */
.popup.success {
    background-color: #1b5e20; /* Deep green for success */
}

.popup.error {
    background-color: #0a9ff0; /* Deep red for error */
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: #ffffff; /* White color for the icon */
}

.popup-text {
    max-width: 150px;
}

.popup-button {
    height: 45px !important;
    font-weight: 700;
}
/* Animation to pop in */
@keyframes popIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 0.9;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    90% { opacity: 0.9; }
    100% { opacity: 0; }
}