.aboutWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    padding: 20px;
    top: 5.5rem;
    color: white;
    height: 67vh;
}

.about {
    font-size: large;
}