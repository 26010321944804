.pictureHeaderContainer {
    display: flex;
    align-self: stretch;
}

.redirectGalleryButtonContainer {
    height: 45px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 2.75rem;
    color: white;
    gap: 15px;
    border-radius: 5px;

    @media only screen and (max-width: 680px) {
        padding: 0;
    }
}

.galleryLink {
    text-decoration: none;
    color: white;
    width: 250px;
}

.back-arrow-icon {
    fill: white;
}