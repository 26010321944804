.social-media-bar-wrapper {
    padding: 60px;
    padding-left: 4rem;
    display: flex;
    flex-wrap:wrap;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, oklch(0.9 0.1 240 / 0.95), oklch(0.9 0.1 240 / 0.95)) padding-box,
    conic-gradient(from var(--bg-angle) in oklch longer hue, oklch(0.97 0.2 0) 0 0) border-box;
    opacity: 0.75;
    color: #2A2A2A;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
}

.social-media-link img {
    padding: 0.5rem;
    max-width: 40px;
    max-height: 40px;
}

.social-media-link:hover {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(128deg) brightness(108%) contrast(101%);
}

.contact-container {
    padding-left: 20px;
}