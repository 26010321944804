.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;
}
  
.carousel-inner {
    display: flex;
    overflow: hidden;
}

.carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    position: relative;
    flex: 0 0 245px; /* Each item has a fixed width of 200px */
    height: 300px;
    width: 230px;
    margin: 0 10px; /* Optional spacing between items */
    text-decoration: none;
}

.carousel-item:hover {
    transform: scale(1.03);
}

.carousel-image {
    width: 245px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.carousel-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 55px;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px;
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.carousel-title {
    font-size: 1rem;
    margin: 0;
}

.carousel-description {
    font-size: 0.8rem;
    margin: 4px 0 0;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    z-index: 1;
    font-size: 1.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.carousel-arrow.left {
    left: 10px;
}

.carousel-arrow.right {
    right: 10px;
}

.carousel-arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}
